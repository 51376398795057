import React from 'react'
import { graphql } from 'gatsby'
import PageHelmet from '../components/PageHelmet'

const PrivacyAndTermsPage = ({
  data: {
    privacyAndTerm: {
      title,
      pageContentNode: {
        childMarkdownRemark: { html },
      },
    },
  },
}) => (
  <>
    <PageHelmet title={title} />
    <h1 dangerouslySetInnerHTML={{ __html: title }} />
    <div dangerouslySetInnerHTML={{ __html: html }} />
  </>
)

export const query = graphql`
  {
    privacyAndTerm: datoCmsPrivacyAndTerm {
      title
      pageContentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default PrivacyAndTermsPage
